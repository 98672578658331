import React, { useEffect, useState } from 'react';
import { getCertificateDetail } from '../services/apiServices';

function Learning() {
  const [details, setDetail] = useState(null);
  const [error, setError] = useState(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const learningId = queryParams.get('id');
    console.log("Learning ID:", learningId);

    async function getCertificateDetails(learningId) {
      try {
        const response = await getCertificateDetail(learningId);
        if (response.statusCode === 200) {
          setDetail(response.payLoad?.response);
        } else {
          setError(true);
        }
      } catch (err) {
        setError(true);
        console.error("Error fetching certificate details:", err);
      }
    }

    if (learningId) {
      getCertificateDetails(learningId);
    } else {
      setError(true);
    }
  }, []);

  return (
    <div className="h-screen flex flex-col items-center bg-orange-100 text-gray-800">
      <div className="container max-w-4xl mx-auto p-6">
        {details ? (
          <>
            {/* Basic Information Section */}
            <div className="bg-white shadow-lg rounded-lg p-6 mb-8">
              <h2 className="text-2xl font-semibold text-orange-600">Certificate Information</h2>
              <div className="mt-4">
                {/* <p><strong>Certificate ID:</strong> {details.certificateId}</p> */}
                <p><strong>Recipient Name:</strong> {details.name}</p>
                {/* <p><strong>Completion Date:</strong> {new Date(details.completionDate).toLocaleDateString()}</p> */}

                <p>
                  <strong>Issued At:</strong>
                  {new Intl.DateTimeFormat('en-GB', { day: 'numeric', month: 'short', year: 'numeric' }).format(new Date(details.issuedAt))}
                </p>

                {/* <p><strong>Visits:</strong> {details.visited}</p> */}
                {/* <p><strong>Status:</strong> {details.isLive ? "Live" : "Inactive"}</p> */}
              </div>
            </div>

            {/* Certificate Image Section */}
            <div className="bg-white shadow-lg rounded-lg p-6 mb-8">
              <h2 className="text-2xl font-semibold text-orange-600">Certificate Image</h2>
              {details.certificateImg ? (
                <img src={details.certificateImg} alt="Certificate" className="mt-4 rounded-lg shadow-lg w-full max-w-md mx-auto" />
              ) : (
                <div className="mt-4 text-center text-xl text-orange-500">
                  <p>No certificate image found. But don't worry, you're still awesome!</p>
                </div>
              )}
            </div>

            {/* Avatar and QR Code Section */}
            <div className="bg-white shadow-lg rounded-lg p-6 mb-8">
              <h2 className="text-2xl font-semibold text-orange-600">Avatar & QR Code</h2>
              <div className="mt-4 flex items-center justify-around">
                <div className="flex flex-col items-center">
                  <img src={details.avatar} alt="Avatar" className="rounded-full w-24 h-24 shadow-lg" />
                  <p className="mt-2 text-sm text-gray-500">Recipient Avatar</p>
                </div>
                <div className="flex flex-col items-center">
                  <img src={details.qrCode} alt="QR Code" className="w-24 h-24 shadow-lg" />
                  <p className="mt-2 text-sm text-gray-500">Scan to View Certificate</p>
                </div>
              </div>
            </div>
          </>
        ) : error ? (
          <div className="bg-white shadow-lg rounded-lg p-6 text-center">
            <h2 className="text-2xl font-semibold text-orange-600">Oops!</h2>
            <p className="mt-4 text-xl text-gray-700">
              We couldn’t find your certificate. It's like searching for a unicorn in a haystack.
            </p>
            <p className="mt-2 text-sm text-gray-500">Try again or contact support if the problem persists!</p>
          </div>
        ) : (
          <div className="bg-white shadow-lg rounded-lg p-6 text-center">
            <p>Loading your certificate details...</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default Learning;